<template>
	<div class="nav-wrap">
		<div class="head" :class="navbg?'navbgClass':''">
			<div class="page">
				<div class="logo-warp" @click="logoTap('index')">
					<div class="logo"></div>
				</div>
				<div class="nav">
					<div v-for="(item,index) in NavList" class="nav-item" :key="index" 
						@click.stop="navTo(item)">
						{{item.name}}
					</div>
				</div>
				<div class="tool">
					<!-- <div class="consult">预约咨询</div> -->
					<div class="member iconfont icon-people" @click="member"></div>
					<div class="search iconfont icon-daohang" @click="moretap" ></div>
				</div>
			</div>
		</div> 
		<div class="drawer animate__animated" v-if="drawer"  :class="drawer?'animate__slideInRight':''">
			<div class="iconfont icon-guanbi" @click="closetap"></div>
			<div class="drawer-list">
				<div v-for="(drawerItem,drawerIndex) in drawerList" class="drawer-item" 
				:key="drawerIndex"  @click="drawertap(drawerIndex,drawerItem.link)" :class="drawerItem.checked?'active-drawer':''">{{drawerItem.name}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import {
		mapState
	} from 'vuex'
	export default {
		inject: ['reload'], // 引入方法
		name: 'Header',
		props:{
		  navbg:{
		    type:Boolean,
		    default:false
		  },

		},
		data() {
			return {
				drawer:false,
				drawerList:[
					{
						name:'关于小院',
						current:0,
						checked:true,
						link:{
							pathName:'lifeBrand'
						},
					},
					{name:'资讯中心',
					current:1,
					checked:false,
					link:{
						pathName:'newsList',
						params:'32,39,29,35,26'
					}},
					{name:'在线商城',
					current:2,
					checked:false,
					link:{
						url:'http://mall.xiaoyuan.com.cn/'
					}},
					{name:'企业合作',
					current:3,
					checked:false,
					link:{
						pathName:'lifeContact'
					}},
					{name:'投诉建议',
					current:4,
					checked:false,
					link:{
						pathName:'feedback'
					}},
					{name:'加入我们',
					current:5,
					checked:false,
					link:{
						url:'http://hr.xiaoyuan.com.cn/index/hr/index.html'
					}},
				],
				NavList: [
					{
						name: "小院",
						pathName: "project",
						params:'yard',
					},
					{
						name: "Smart0",
						pathName: "smart0",
					},
					{
						name: "空中花园",
						pathName: "project",
						params:'hanging_gardens',
					},
					{
						name: "小院在线",
						pathName: "project", 
						params:'yard_online'
					},
					{
						name: "小院咖啡",
						pathName: "starbucks",
					},
					{
						name: "图书馆",
						pathName: "library",
					},
					{
						name: "健身",
						pathName: "fitness",
					},
					{
						name: "共享办公",
						pathName: "sharedOffice",
					},

				],

			}
		},
		created() {
			if(this.$route.name!='index'){
				var obj={
					name: "首页",
					pathName: "index",
				}
				this.NavList.unshift(obj)
			}
		},
		methods: {
			jump(e){
				if (e.url) {
					window.open(e.url)
					return;
				}
				if (e.pathName == this.$route.name) {
					this.reload()
				}
				this.$router.push({
					name: e.pathName,
					params: {
						paramsName: e.params,
					}
				});
			},
			navTo: utils.debounce(function(e) {
				this.jump(e);		
			}, 200, true),
			logoTap(url){
				this.$router.push({
					name: url
				});
			},
			drawertap(index,e){
				for(var item of this.drawerList){
					if(item.current==index){
						item.checked=true;
					}else{
						item.checked=false;
					}
				}
				this.jump(e);
			},
			member: async function(){
				let isLogin = await this.$store.dispatch('isLogin');
				if (isLogin) {
					this.$router.push({
						name: 'member'
					});
					;
				}else{
					this.$router.push({
						name: 'login'
					})
				}
			},
			moretap(){
				this.drawer=true;
			},
			closetap: function() {
				this.drawer = false;
			},
		},
	}
</script>

<style lang="scss" scoped>
	.drawer{
		height: 100%;
		width: 310px;
		position: fixed;
		top: 0;
		right: 0;
		z-index: 99;
		background-color: #fff;
		font-size: 18px;
		color: #000000;
		padding: 36px 0;
		box-sizing: border-box;
		@media only screen and (max-width:1800px){
			.nav {
				display:none;
			}
		}
		.drawer-list{
			padding-top: 70px;
		}
		.icon-guanbi{
			font-weight: blod;
			color: #000000;
			position: absolute;
			right: 40px;
			top: 70px;
		}
		.drawer-item{
			width: 234px;
			height: 36px;
			border-radius: 12px;
			line-height: 36px;
			margin:0 auto 20px;
			padding-left: 16px;
			&.active-drawer{
				background: #F2F2F2;
			}
		}
	}
	.head {
		padding: 0 32px 0 44px;
		width: 100%;
		box-sizing: border-box;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		color: #fff;
		background-color: rgba(255, 255, 255, 0);
		transition: all 0.3s;
		&.navbgClass{
			background-image: url('../assets/images/index2022/navbg.png');
		}
		.page {
			height: 64px;
			display: flex;
			justify-content: flex-start;
			width: 100%;
			.logo-warp {
				height: 100%;
				width: 150px;
				display: flex;
				align-items: center;
				.logo {
					width: 141px;
					height: 28px;
					background-image: url('../assets/images/index2022/logo2022.png');
					background-size: 100% 100%;
				}
			}
			.nav {
				flex: 1;
				display: flex;
				height: 100%;
				font-size: 18px;
				justify-content: center;
				color: inherit;
				.nav-item {
					padding: 0 16px;
					align-items: center;
					display: flex;
					cursor: pointer;
				}
			}
			.tool {
				display: flex;
				height: 100%;
				align-items: center;
				margin-left: auto;
				cursor: pointer;
				.iconfont {
					font-size: 18px;
					color: inherit;
					margin-left: 25px;
				}
				.consult {
					font-size: 18px;
				}
			}
			
		}
	}
</style>
